import {
  DistributionPanel,
  EnergyPro,
  EnergyDeviceFromApi,
  ObjectById,
  EnergyDevicePorts,
  CircuitBreakerFromApiResponse,
  SiteEnergyCalculationMode,
} from '@energybox/react-ui-library/dist/types';
import {
  mapArrayToObject,
  classNames,
  KW_UNIT,
  global,
  isDefined,
} from '@energybox/react-ui-library/dist/utils';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from '../../../../reducers';
import NewEquipmentModal from '../../../Equipment/NewEquipmentModal';
import { ctPolarityLabel } from '../../../Selects/SelectCtPolarity';
import SelectEquipment from '../../../Selects/SelectEquipment';
import {
  DPTableState,
  ActionPayLoad,
  useEnergyProStreamDataBySensorId,
  UpdateEntity,
  UpdateEnergyDeviceAction,
  getPowerFactor,
  getCurrent,
  acceptedPatchBreakerFields,
  getActivePower,
  getPhase,
  getVoltage,
} from './../DPSetUpTable';
import rPick from 'ramda/src/pick';
import {
  patch as patchCircuitBreaker,
  showEditCircuitBreakerModal,
} from '../../../../actions/circuit_breakers';
import styles from './../DPSetUpTable.module.css';
import { Checkbox } from '@energybox/react-ui-library/dist/components';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import {
  convertCtToTitle,
  getPhaseDisplayText,
  isSiteTotalChecked,
} from '../../../../utils/energyPro';
import DeviceConnectionStatus from '../../../../components/DeviceConnectionStatus';
import EditCircuitBreakerModal from '../../../CircuitBreakers/EditCircuitBreakerModal/EditCircuitBreakerModal';
import { useGetSite } from '../../../../hooks/useSites';
import PhaseTooltip from './PhaseTooltip/PhaseTooltip';
import { StagingSensorConfig } from '../../../../reducers/energy_devices';
import { addStagingSensorConfig } from '../../../../actions/energy_devices';
import {
  StatefulSelectCtPolarity,
  StatefulSelectPhase,
} from './StatefulSelects';

type SensorBusTreeProps = {
  title: string;
  distributionPanel: DistributionPanel;
  siteId: number;
  configByDeviceId: DPTableState;
  energyPro: EnergyPro;
  setUnconfirmedUpdateAction: (updateAction: ActionPayLoad | undefined) => void;
  nodes: EnergyDeviceFromApi[];
  deviceById: ObjectById<EnergyDeviceFromApi>;
  localDispatch: React.Dispatch<ActionPayLoad>;
  showActiveOnly?: boolean;
  isEditing?: boolean;
};

const SensorBusTree = ({
  title,
  distributionPanel,
  siteId,
  configByDeviceId,
  energyPro,
  setUnconfirmedUpdateAction,
  deviceById,
  nodes,
  localDispatch,
  showActiveOnly,
  isEditing,
}: SensorBusTreeProps) => {
  const reduxDispatch = useDispatch();

  const [expanded, setExpanded] = useState(true);
  const [newEquipmentPendingAction, setNewEquipmentPendingAction] = useState<
    ActionPayLoad | undefined
  >(undefined);

  const circuitbreakerEditById = useSelector(
    ({ circuitBreakers }: ApplicationState) => {
      return circuitBreakers.editById;
    }
  );
  const { energyCalculationMode } = useGetSite(siteId) || {};

  useEffect(() => {
    if (!newEquipmentPendingAction) return;
    const { id: energyDeviceId, port } = newEquipmentPendingAction;
    if (!port) return;
    const portData = configByDeviceId[energyDeviceId][port];
    const { breakerId } = portData;
    const equipmentIdFromRedux: number | undefined =
      circuitbreakerEditById[breakerId]?.fields?.equipmentId;
    if (equipmentIdFromRedux === undefined) return;
    if (
      equipmentIdFromRedux !==
      configByDeviceId.breakersById[breakerId]?.equipmentId
    ) {
      localDispatch({
        ...newEquipmentPendingAction,
        value: equipmentIdFromRedux,
      });
      const payload = {
        ...rPick(acceptedPatchBreakerFields, portData),
        id: breakerId,
        equipmentId: equipmentIdFromRedux,
      };
      reduxDispatch(
        patchCircuitBreaker(distributionPanel.id, breakerId, payload)
      );
      setNewEquipmentPendingAction(undefined);
    }
  }, [
    configByDeviceId,
    circuitbreakerEditById,
    newEquipmentPendingAction,
    distributionPanel,
    localDispatch,
  ]);

  const energySensorsReadingById = useEnergyProStreamDataBySensorId(
    energyPro.id
  );

  if (nodes.length === 0) return null;

  const { breakersById } = configByDeviceId;

  const headerStyle = classNames(styles.tableHeader, styles.bottomSeparator);
  const headerStyleWithPadding = classNames(
    styles.tableHeader,
    styles.dropdownHeaderPadding,
    styles.bottomSeparator
  );

  const addSensorConfig = (key: string, sensorConfig: StagingSensorConfig) => {
    reduxDispatch(addStagingSensorConfig(key, sensorConfig));
  };

  return (
    <div className={styles.busDeviceRoot}>
      <div
        className={styles.busDeviceHeader}
        onClick={() => setExpanded(!expanded)}
      >
        {expanded ? (
          <IoIosArrowUp color="var(--accent-base)" size={'1.5em'} />
        ) : (
          <IoIosArrowDown color="var(--accent-base)" size={'1.5em'} />
        )}
        <span>{title}</span>
      </div>

      <div
        className={classNames(
          styles.busDeviceContainer,
          !expanded && styles.hidden
        )}
      >
        <div className={classNames(styles.sensorBusTableGen2)}>
          <div>&nbsp;</div>
          <div className={headerStyleWithPadding}>Index</div>
          <div className={headerStyle}>Equipment</div>
          <div className={headerStyle}>Site Total</div>
          <div className={headerStyleWithPadding}>Breakers Name</div>
          <div className={headerStyleWithPadding}>CT Type</div>
          <div className={headerStyleWithPadding}>CT Polarity</div>
          <div className={headerStyle} style={{ gridColumn: 'span 2' }}>
            Phase
            <PhaseTooltip />
          </div>
          <div className={headerStyle}>Active Power ({KW_UNIT})</div>
          <div className={headerStyle}>Current (A)</div>
          <div className={headerStyle}>Voltage (V)</div>
          <div className={headerStyle}>Power Factor</div>
        </div>
        {nodes.map((node, deviceIndex, _) => {
          const device = deviceById[node.id];
          const portsData = mapArrayToObject(
            Object.values(configByDeviceId[node.id] || {}),
            'port'
          );
          const portRows = new Array(EnergyDevicePorts[device._entity]).fill(0);
          const title = 'Energy Spider ' + (deviceIndex + 1);
          return (
            <>
              <div
                className={styles.sensorBusTableGen2}
                key={`busDevice${node.id}`}
              >
                {deviceIndex != 0 && (
                  <>
                    <div></div>
                    <div className={styles.busDeviceSeparatorGen2}></div>
                  </>
                )}
                <div
                  className={classNames(styles.busDeviceTitle)}
                  style={{ gridRow: 'span ' + portRows.length }}
                >
                  <div className={styles.deviceSeparator}>&nbsp;</div>
                  <div>{title}</div>
                </div>
                {portRows.map((_, row) => {
                  const data = portsData[row + 1];

                  if (data === undefined) return null;

                  const {
                    port,
                    energyDeviceId,
                    energySensorId,
                    ct,
                    reversePolarity,
                    phase,
                    breakerId,
                    isMainBreaker,
                    disabled,
                  } = data || {};

                  const breaker: CircuitBreakerFromApiResponse | undefined =
                    breakersById[breakerId];

                  if (!device) return null;
                  if (showActiveOnly && disabled && !breaker?.equipmentId)
                    return null;

                  const detectedPhase = getPhase(
                    energySensorId,
                    energySensorsReadingById
                  );

                  const { phaseToShow, phaseSource } = getPhaseDisplayText(
                    phase,
                    detectedPhase
                  );

                  const siteTotalChecked =
                    energyCalculationMode &&
                    isSiteTotalChecked(
                      distributionPanel.mdp,
                      false,
                      energyCalculationMode,
                      breaker.siteTotal
                    );
                  const isCheckboxDisabled =
                    breaker === undefined ||
                    energyCalculationMode !==
                      SiteEnergyCalculationMode.FLAGGED_BREAKERS;

                  return (
                    <React.Fragment key={`bus${node.id}DevicePort${port}`}>
                      <div
                        className={row % 2 === 0 ? styles.white : styles.gray}
                      >
                        <div
                          className={classNames(
                            styles.centered,
                            styles.tableCellPadding
                          )}
                        >
                          <DeviceConnectionStatus
                            connectionStatus={
                              data && !disabled ? true : 'INACTIVE'
                            }
                            hideText
                          />
                          Port {port}
                        </div>
                      </div>
                      <div
                        className={row % 2 === 0 ? styles.white : styles.gray}
                      >
                        {newEquipmentPendingAction?.port === port &&
                          newEquipmentPendingAction?.id === energyDeviceId && (
                            <NewEquipmentModal
                              lockSiteId={siteId}
                              breakerIdToUpdate={breakerId || 'new'}
                            />
                          )}
                        <SelectEquipment
                          onCreateEquipmentModalOpen={() => {
                            setNewEquipmentPendingAction({
                              type:
                                UpdateEnergyDeviceAction.BREAKER_EQUIPMENT_ID,
                              deviceTitle: device.title,
                              id: energyDeviceId,
                              port,
                              entity: UpdateEntity.BREAKER,
                            });
                          }}
                          noBottomLine
                          disabled={!breakerId || isMainBreaker}
                          onSelect={updated =>
                            setUnconfirmedUpdateAction({
                              type:
                                UpdateEnergyDeviceAction.BREAKER_EQUIPMENT_ID,
                              deviceTitle: device.title,
                              id: energyDeviceId,
                              port,
                              value: updated,
                              entity: UpdateEntity.BREAKER,
                            })
                          }
                          value={breaker?.equipmentId}
                          siteId={siteId}
                        />
                      </div>
                      <div
                        className={row % 2 === 0 ? styles.white : styles.gray}
                      >
                        <div>
                          <Checkbox
                            checked={siteTotalChecked}
                            disabled={isCheckboxDisabled}
                            className={styles.delete}
                            size={18}
                            onChange={() =>
                              setUnconfirmedUpdateAction({
                                type: UpdateEnergyDeviceAction.SITE_TOTAL,
                                deviceTitle: device.title,
                                id: energyDeviceId,
                                breakerId: breaker.id,
                                port,
                                value: !breaker.siteTotal,
                                entity: UpdateEntity.BREAKER,
                              })
                            }
                          />
                        </div>
                      </div>

                      <div
                        className={row % 2 === 0 ? styles.white : styles.gray}
                      >
                        <div
                          style={{ position: 'relative' }}
                          className={styles.tableCellPadding}
                        >
                          <span
                            className={styles.breakerClickable}
                            onClick={() => {
                              reduxDispatch(
                                showEditCircuitBreakerModal(breaker?.id)
                              );
                            }}
                          >
                            {breaker?.title || global.NOT_AVAILABLE}
                          </span>
                          <EditCircuitBreakerModal
                            breakerId={breaker?.id}
                            panelId={distributionPanel.id}
                            energyPro={energyPro}
                          />
                        </div>
                      </div>
                      <div
                        className={row % 2 === 0 ? styles.white : styles.gray}
                      >
                        <div>
                          <div className={styles.tableCellPadding}>
                            {convertCtToTitle(ct) || global.NOT_AVAILABLE}
                          </div>
                        </div>
                      </div>
                      <div
                        className={row % 2 === 0 ? styles.white : styles.gray}
                      >
                        <div>
                          {!isEditing ? (
                            <span className={styles.tableCellPadding}>
                              {(isDefined(reversePolarity) &&
                                ctPolarityLabel[String(reversePolarity)]) ||
                                global.NOT_AVAILABLE}
                            </span>
                          ) : (
                            <StatefulSelectCtPolarity
                              value={reversePolarity}
                              onSelect={updated =>
                                addSensorConfig('reversePolarity', {
                                  energyDeviceId: energyDeviceId,
                                  port,
                                  reversePolarity: updated,
                                  phase,
                                })
                              }
                            />
                          )}
                        </div>
                      </div>
                      <div
                        className={row % 2 === 0 ? styles.white : styles.gray}
                        style={
                          (isEditing && { gridColumn: 'span 2' }) || undefined
                        }
                      >
                        <div>
                          {!isEditing ? (
                            <span>{phaseToShow}</span>
                          ) : (
                            <StatefulSelectPhase
                              value={phase}
                              onSelect={updated =>
                                addSensorConfig('phase', {
                                  energyDeviceId: energyDeviceId,
                                  port,
                                  phase: updated,
                                  reversePolarity,
                                })
                              }
                            />
                          )}
                        </div>
                      </div>
                      <div
                        className={row % 2 === 0 ? styles.white : styles.gray}
                        style={(isEditing && { display: 'none' }) || undefined}
                      >
                        <span>{phaseSource}</span>
                      </div>
                      <div
                        className={row % 2 === 0 ? styles.white : styles.gray}
                      >
                        <div>
                          {getActivePower(
                            energySensorId,
                            energySensorsReadingById
                          )}
                        </div>
                      </div>
                      <div
                        className={row % 2 === 0 ? styles.white : styles.gray}
                      >
                        <div>
                          {getCurrent(energySensorId, energySensorsReadingById)}
                        </div>
                      </div>
                      <div
                        className={row % 2 === 0 ? styles.white : styles.gray}
                      >
                        <div>
                          {getVoltage(energySensorId, energySensorsReadingById)}
                        </div>
                      </div>
                      <div
                        className={row % 2 === 0 ? styles.white : styles.gray}
                      >
                        <div>
                          {getPowerFactor(
                            energySensorId,
                            energySensorsReadingById
                          )}
                        </div>
                      </div>
                    </React.Fragment>
                  );
                })}
              </div>
            </>
          );
        })}
      </div>
    </div>
  );
};

export default SensorBusTree;
