import {
  DistributionPanel,
  EnergyPro,
  MainBreaker,
  ResourceType,
  SiteEnergyCalculationMode,
} from '@energybox/react-ui-library/dist/types';
import {
  ActionPayLoad,
  DevicePortDisplayData,
  DPTableState,
  getActivePower,
  getCurrent,
  getPhase,
  getPowerFactor,
  getVoltage,
  UpdateEnergyDeviceAction,
  UpdateEntity,
  useEnergyProStreamDataBySensorId,
} from './../DPSetUpTable';
import { EntityInfoToSub } from '@energybox/react-ui-library/dist/types/StreamApi';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from '../../../../reducers';
import React, { useEffect } from 'react';
import {
  classNames,
  global,
  KW_UNIT,
} from '@energybox/react-ui-library/dist/utils';
import styles from './../DPSetUpTable.module.css';
import DeviceOnlineState, {
  DisplayType,
} from '../../../DeviceStatus/DeviceOnlineState';
import { Checkbox } from '@energybox/react-ui-library/dist/components';
import BreakerDropdown from './../BreakerDropdown';
import { SelectEnergyDeviceCt } from '../../../../components/EditEnergyDeviceSensorForm/EditEnergyDeviceSensorForm';
import SelectCtPolarity from '../../../Selects/SelectCtPolarity';
import SelectPhase from '../../../Selects/SelectPhase';
import NewEnergyDeviceSensorModal from '../../../EnergyDevices/NewEnergyDeviceSensorModal';
import {
  convertCtToTitle,
  getPhaseDisplayText,
  isSiteTotalChecked,
} from '../../../../utils/energyPro';
import DeviceConnectionStatus from '../../../../components/DeviceConnectionStatus';
import {
  subscribeToProposedPhaseReadings,
  unsubscribeToProposedPhaseReadings,
} from '../../../../actions/streamApi';
import { useGetSite } from '../../../../hooks/useSites';
import { showEditEnergyProModal } from '../../../../actions/energy_pros';
import EditEnergyProModal from '../../../EnergyPros/EditEnergyProModal/EditEnergyProModal';
import PhaseTooltip from './PhaseTooltip/PhaseTooltip';
import {
  StatefulSelectCtPolarity,
  StatefulSelectPhase,
} from './StatefulSelects';
import { StagingSensorConfig } from '../../../../reducers/energy_devices';
import { addStagingSensorConfig } from '../../../../actions/energy_devices';

type EnergyProConfigProps = {
  distributionPanel: DistributionPanel;
  siteId: number;
  configByDeviceId: DPTableState;
  energyPro: EnergyPro;
  energyProSubscriptionInfo: EntityInfoToSub[] | undefined;
  setUnconfirmedUpdateAction: (updateAction: ActionPayLoad | undefined) => void;
  setNewSensorDevice: (epro: EnergyPro) => void;
  isEditing?: boolean;
};

const EnergyProConfig = ({
  distributionPanel,
  siteId,
  configByDeviceId,
  energyPro,
  energyProSubscriptionInfo,
  setUnconfirmedUpdateAction,
  isEditing,
}: EnergyProConfigProps) => {
  const dispatch = useDispatch();
  const portsData = Object.values(configByDeviceId[energyPro.id] || {});

  // for portsData, first sort by port number, then fill up to the number of poles
  // say it's 1 and 3, and poles are 3, then fill 2
  const poles = distributionPanel.mainBreaker.poles;
  portsData.sort((a, b) => a.port - b.port);
  let processedPortsData: (DevicePortDisplayData | undefined)[] = [];
  for (let i = 0; i < poles; i++) {
    const sensor = portsData.find(data => data?.port === i + 1);
    processedPortsData.push(sensor);
  }

  const { energyCalculationMode } = useGetSite(siteId) || {};
  const energySensorsReadingById = useEnergyProStreamDataBySensorId(
    energyPro.id
  );

  const showingNewEnergyDeviceSensorModal = useSelector(
    ({ energyDevices }: ApplicationState) => {
      return energyDevices.showNewEnergyDeviceSensorModal;
    }
  );

  const addSensorConfig = (key: string, sensorConfig: StagingSensorConfig) => {
    dispatch(addStagingSensorConfig(key, sensorConfig));
  };

  const { id, title, uuid, firmwareVersion } = energyPro;

  useEffect(() => {
    if (!energyPro) return;
    dispatch(
      subscribeToProposedPhaseReadings(
        energyPro.vendor,
        energyPro.uuid,
        energyPro.id
      )
    );

    return () => {
      dispatch(
        unsubscribeToProposedPhaseReadings(
          energyPro.vendor,
          energyPro.uuid,
          energyPro.id
        )
      );
    };
  }, [energyPro]);

  return (
    <>
      <div
        className={classNames(styles.busDeviceTableGen2, styles.topSeparator)}
      >
        <div className={styles.tableHeader}>Device Name</div>
        <div
          className={classNames(
            styles.tableHeader,
            styles.dropdownHeaderPadding
          )}
        >
          Index
        </div>
        <div className={styles.tableHeader}>Site Total</div>
        <div
          className={classNames(
            styles.tableHeader,
            styles.dropdownHeaderPadding
          )}
        >
          Breakers Name
        </div>
        <div
          className={classNames(
            styles.tableHeader,
            styles.dropdownHeaderPadding
          )}
        >
          CT Type
        </div>
        <div
          className={classNames(
            styles.tableHeader,
            styles.dropdownHeaderPadding
          )}
        >
          CT Polarity
        </div>
        <div
          className={classNames(styles.tableHeader)}
          style={{ gridColumn: 'span 2' }}
        >
          Phase
          <PhaseTooltip />
        </div>
        <div className={styles.tableHeader}>Active Power ({KW_UNIT})</div>
        <div className={styles.tableHeader}>Current (A)</div>
        <div className={styles.tableHeader}>Voltage (V)</div>
        <div className={styles.tableHeader}>Power Factor</div>
      </div>
      <div className={classNames(styles.busDeviceTableGen2)}>
        <div style={{ gridRow: 'span 3', backgroundColor: 'white' }}>
          <div className={styles.eproDeviceName}>
            {energyProSubscriptionInfo && (
              <DeviceOnlineState
                displayType={DisplayType.STATUS_ONLY_WITHOUT_TEXT}
                devices={energyProSubscriptionInfo}
              />
            )}
            <div style={{ position: 'relative' }}>
              <span
                className={styles.eproDeviceNameTitle}
                onClick={() => {
                  dispatch(showEditEnergyProModal(String(id)));
                }}
                style={{ cursor: 'pointer' }}
              >
                {title}
              </span>

              <EditEnergyProModal
                id={energyPro.id}
                energyPro={energyPro}
                siteId={siteId}
                distributionPanelEnergySource={distributionPanel.energySource}
              />

              <br />
              <span className={styles.eproDeviceNameSubTitle}>
                UUID: {uuid ? uuid : global.NOT_AVAILABLE}
                <br />
                FW: {firmwareVersion ? firmwareVersion : global.NOT_AVAILABLE}
              </span>
            </div>
          </div>
        </div>

        {processedPortsData.map((data, row, rows) => {
          if (data === undefined) {
            return (
              <React.Fragment key={`blankPort${row}`}>
                <div className={row % 2 === 0 ? styles.white : styles.gray}>
                  <div
                    className={classNames(
                      styles.centered,
                      styles.dropdownHeaderPadding
                    )}
                  >
                    <DeviceConnectionStatus
                      connectionStatus="INACTIVE"
                      hideText
                    />
                    Port {row + 1}
                  </div>
                </div>
                {new Array(10).fill(0).map((_, index) => (
                  <div
                    className={classNames(
                      row % 2 === 0 ? styles.white : styles.gray,
                      index > 0 && index < 5 && styles.dropdownHeaderPadding
                    )}
                    key={`blankPort${index}`}
                  >
                    {global.NOT_AVAILABLE}
                  </div>
                ))}
              </React.Fragment>
            );
          }

          const {
            port,
            energyDeviceId,
            energySensorId,
            ct,
            reversePolarity,
            phase,
            disabled,
          } = data;

          const breaker: MainBreaker | undefined =
            distributionPanel.mainBreaker;

          const backgroundColor = row % 2 === 0 ? 'white' : 'gray';
          const siteTotalChecked =
            energyCalculationMode &&
            isSiteTotalChecked(
              distributionPanel.mdp,
              true,
              energyCalculationMode,
              breaker.siteTotal
            );
          const isCheckboxDisabled =
            breaker === undefined ||
            energyCalculationMode !==
              SiteEnergyCalculationMode.FLAGGED_BREAKERS;

          const detectedPhase = getPhase(
            energySensorId,
            energySensorsReadingById
          );

          const { phaseToShow, phaseSource } = getPhaseDisplayText(
            phase,
            detectedPhase
          );

          return (
            <React.Fragment key={`CT_Port${port}`}>
              <div
                className={classNames(
                  styles[backgroundColor],
                  styles.dropdownHeaderPadding
                )}
              >
                <DeviceConnectionStatus
                  connectionStatus={disabled || !ct ? 'INACTIVE' : true}
                  hideText
                />
                Port {port}
              </div>
              <div className={styles[backgroundColor]}>
                <Checkbox
                  checked={siteTotalChecked}
                  disabled={isCheckboxDisabled}
                  onChange={() => {
                    if (breaker)
                      setUnconfirmedUpdateAction({
                        type: UpdateEnergyDeviceAction.SITE_TOTAL,
                        deviceTitle: energyPro.title,
                        id: energyDeviceId,
                        breakerId: +breaker.id,
                        port,
                        value: !breaker.siteTotal,
                        entity: UpdateEntity.MAIN_BREAKER,
                      });
                  }}
                />
              </div>
              <div className={styles[backgroundColor]}>
                <div
                  className={classNames(
                    styles.eproDeviceNameTitle,
                    styles.tableCellPadding
                  )}
                >
                  {data.breaker.title}
                </div>
              </div>
              <div className={styles[backgroundColor]}>
                <div className={styles.tableCellPadding}>
                  {convertCtToTitle(ct)}
                </div>
              </div>
              <div className={styles[backgroundColor]}>
                {isEditing ? (
                  <StatefulSelectCtPolarity
                    value={reversePolarity}
                    onSelect={updated =>
                      addSensorConfig('reversePolarity', {
                        energyDeviceId: energyDeviceId,
                        port,
                        reversePolarity: updated,
                        phase,
                      })
                    }
                  />
                ) : (
                  <div className={styles.tableCellPadding}>
                    {reversePolarity ? 'Reverse' : 'Normal'}
                  </div>
                )}
              </div>
              <div
                className={styles[backgroundColor]}
                style={(isEditing && { gridColumn: 'span 2' }) || undefined}
              >
                {isEditing ? (
                  <StatefulSelectPhase
                    value={phase}
                    onSelect={updated =>
                      addSensorConfig('phase', {
                        energyDeviceId: energyDeviceId,
                        port,
                        phase: updated,
                        reversePolarity,
                      })
                    }
                  />
                ) : (
                  <div>{phaseToShow}</div>
                )}
              </div>
              <div
                className={styles[backgroundColor]}
                style={(isEditing && { display: 'none' }) || undefined}
              >
                {phaseSource}
              </div>

              <div className={styles[backgroundColor]}>
                {getActivePower(energySensorId, energySensorsReadingById)}
              </div>
              <div className={styles[backgroundColor]}>
                {getCurrent(energySensorId, energySensorsReadingById)}
              </div>
              <div className={styles[backgroundColor]}>
                {getVoltage(energySensorId, energySensorsReadingById)}
              </div>
              <div className={styles[backgroundColor]}>
                {getPowerFactor(energySensorId, energySensorsReadingById)}
              </div>
            </React.Fragment>
          );
        })}
      </div>
      {showingNewEnergyDeviceSensorModal && energyPro && (
        <NewEnergyDeviceSensorModal
          panelId={distributionPanel.id}
          energyDeviceId={energyPro.id}
          sensors={energyPro.sensors}
          energyProId={energyPro.id}
          breakerId={distributionPanel.mainBreaker.id}
          refetchEnergyPro={true}
        />
      )}
    </>
  );
};

export default EnergyProConfig;
