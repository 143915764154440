import {
  Button,
  DistributionPanel as Panel,
  RadioButton,
  RadioGroup,
  Tab,
  Tabs,
} from '@energybox/react-ui-library/dist/components';
import {
  CircuitBreaker as CircuitBreakerIcon,
  EnergyBar as EnergyBarIcon,
  EnergyPro as EnergyProIcon,
  EnergySpider as EnergySpiderIcon,
  EnergySpiderFlex as EnergySpiderFlexIcon,
  GridView,
  ArrowRight,
} from '@energybox/react-ui-library/dist/icons';
import {
  CircuitBreakerInPanel,
  DistributionPanel,
  DistributionPanelType,
  EnergyDeviceFromApi,
  EnergyDeviceType,
  EnergyPro,
} from '@energybox/react-ui-library/dist/types';
import { isDefined, mapValues } from '@energybox/react-ui-library/dist/utils';
import { getMapFromArrayOneToOne } from '@energybox/react-ui-library/dist/utils/util';
import equals from 'ramda/src/equals';
import React from 'react';
import { MdAdd } from 'react-icons/md';
import { connect } from 'react-redux';
import {
  mapBreakersInPanelToBreakerStore,
  showNewCircuitBreakerModal,
} from '../../../actions/circuit_breakers';
import {
  getDistributionPanel,
  primeForDelete as primePanelForDelete,
  showDeleteDistributionPanelModal,
} from '../../../actions/distribution_panel';
import {
  clearStagingSensorConfig,
  patchSensorConfigs,
  showNewEnergyDeviceModal,
  showNewEnergyDeviceSensorModal,
} from '../../../actions/energy_devices';
import {
  Actions as EnergyProActions,
  getEnergyProsByDistributionPanelId,
  hideUpdateEnergyProConfigModal,
  showNewEnergyProModal,
  showUpdateEnergyProConfigModal,
  updateEnergyProConfiguration,
  primeForDelete as primeEProForDelete,
} from '../../../actions/energy_pros';
import {
  subscribeToEnergyProSensorReadings,
  unsubscribeFromEnergyProSensorReadings,
} from '../../../actions/streamApi';
import EnergyComponentsVerticalBar from '../../../components/EnergyComponentsVerticalBar';
import { VerticalNavBarButton } from '../../../components/EnergyComponentsVerticalBar/EnergyComponentsVerticalBar';
import ShowDetailPageHeader from '../../../components/ShowDetailPageHeader';
import history from '../../../history';
import { ApplicationState } from '../../../reducers';
import { EditDistributionPanel } from '../../../reducers/distribution_panels';
import { EnergyDevicesById } from '../../../reducers/energy_devices';
import { EditById as EnergyProEditById } from '../../../reducers/energy_pros';
import { SubscribedEnergyProSensorsReadingBySensorId } from '../../../reducers/subscribedEnergyPros';
import { Routes } from '../../../routes';
import { ApiError } from '../../../utils/apiErrorFeedback';
import NewCircuitBreakerModal from '../../CircuitBreakers/NewCircuitBreakerModal';
import DeleteDistributionPanelModal from '../../DistributionPanels/DeleteDistributionPanelModal';
import NewEnergyDeviceModal from '../../EnergyDevices/NewEnergyDeviceModal';
import NewEnergyDeviceSensorModal from '../../EnergyDevices/NewEnergyDeviceSensorModal';
import RecursiveEnergyDevicesList from '../../EnergyDevices/RecursiveEnergyDevicesList';
import EnergyProConfiguration from '../../EnergyPros/EnergyProConfiguration/EnergyProConfiguration';
import ResourceFullPath from '../../ResourceFullPath';
import DistributionPanelCharts from '../DistributionPanelCharts';
import DistributionPanelConfiguration from '../DistributionPanelConfiguration/DistributionPanelConfiguration';
import GenericPanelLiveReadingTable from '../DistributionPanelPageLiveReadingTable';
import DeltaPanelLiveReadingTable from '../DistributionPanelPageLiveReadingTable/DeltaPanelLiveReadingTable';
import DPSetUpTable, {
  ConfirmUpdateSensorConfigs,
} from '../DPSetUpTable/DPSetUpTable';
import SelectActiveEnergyPro from '../SelectActiveEnergyPro';
import UpdateModal from '../UpdateModal';
import styles from './ShowDistributionPanelPage.module.css';
import { accessDeniedError } from '../../../utils/ApiError/accessDeniedError';
import DPSettingsCard from '../DPSettingsCard/DPSettingsCard';
import { isEnergyPro1, isEnergyPro2 } from '../../../utils/energyPro';

interface OwnProps {
  id: string;
}

enum EditorView {
  TABLE = 'Table',
  SVG = 'Svg',
}

interface Props extends OwnProps {
  load: () => void;
  distributionPanel?: DistributionPanel;
  energyPros: EnergyPro[];
  editDistributionPanel: EditDistributionPanel;
  energyReadingsBySensorId: SubscribedEnergyProSensorsReadingBySensorId;
  subscribeToEnergyProSensorReadings: typeof subscribeToEnergyProSensorReadings;
  unsubscribeFromEnergyProSensorReadings: typeof unsubscribeFromEnergyProSensorReadings;
  showDeleteDistributionPanelModal: typeof showDeleteDistributionPanelModal;
  showNewEnergyProModal: typeof showNewEnergyProModal;
  primePanelForDelete: () => void;
  primeEProForDelete: (id: string) => void;
  showNewCircuitBreakerModal: typeof showNewCircuitBreakerModal;
  currentSiteId: number;
  mapBreakersInPanelToBreakerStore: (
    breakersInPanel: CircuitBreakerInPanel[]
  ) => void;
  showNewEnergyDeviceModal: typeof showNewEnergyDeviceModal;
  energyDevicesById: EnergyDevicesById;
  showNewEnergyDeviceSensorModal: (energyDeviceId: number) => void;
  showingNewEnergyDeviceSensorModal: boolean;
  streamConnected: boolean;
  updateEnergyProConfiguration: (energyProId: string) => void;
  energyProEditById: EnergyProEditById;
  showUpdateEnergyProConfigModal: (energyProId: string) => void;
  hideUpdateEnergyProConfigModal: (energyProId: string) => void;
  patchSensorConfigs: typeof patchSensorConfigs;
  clearStagingSensorConfig: () => void;
  distributionPanelApiError: ApiError;
  haveStagingConfigs: boolean;
}

interface State {
  activeTab: DistributionPanelTab;
  expandedId: string;
  newEnergyDeviceType: EnergyDeviceType;
  activeEnergyProId: number;
  selectedBreakerId: number;
  //selectedEnergyDevice can either be energyPro or energyBar/Spider/SpiderFlex
  selectedEnergyDevice: SelectedEnergyDevice;
  selectedBreakerWire: SelectedBreakerWire;
  isEnergyProConfigUpdateModalOpen: boolean;
  energyProsForChart: {
    id: number;
    title: string;
  }[];
  editorView: EditorView;
  showUpdateSensorConfigs: boolean;
}

enum DistributionPanelTab {
  PANEL_EDITOR = 'Panel Editor',
  EPRO_LIVE_READING = 'Live Readings',
  CHARTS = 'Charts',
}

export type SelectedEnergyDevice = {
  id: number;
  energyDevicePort: number;
  breakerId: number;
  breakerPole: number;
};

export type SelectedBreakerWire = {
  breakerId: number;
  energyDeviceId: number;
  energyDevicePort: number;
};

const defaultSelectedEnergyDeviceState = {
  id: -1,
  energyDevicePort: -1,
  breakerId: -1,
  breakerPole: -1,
};

const defaultSelectedBreakerWire = {
  breakerId: -1,
  energyDeviceId: -1,
  energyDevicePort: -1,
};

const defaultState = {
  activeTab: DistributionPanelTab.PANEL_EDITOR,
  expandedId: '',
  newEnergyDeviceType: EnergyDeviceType.UNASSIGNED,
  activeEnergyProId: -1,
  selectedBreakerId: -1,
  selectedEnergyDevice: defaultSelectedEnergyDeviceState,
  selectedBreakerWire: defaultSelectedBreakerWire,
  isEnergyProConfigUpdateModalOpen: false,
  energyProsForChart: [],
  editorView: EditorView.SVG,
  runTestModel: false,
  showUpdateSensorConfigs: false,
};

class ShowDistributionPanelPage extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = defaultState;
  }

  pendingSubscribeToEnergyProSensorReadings = false;

  componentDidMount() {
    const {
      load,
      distributionPanel,
      mapBreakersInPanelToBreakerStore,
    } = this.props;

    load();

    if (distributionPanel) {
      mapBreakersInPanelToBreakerStore(distributionPanel.breakers);
      this.changeStateForUrlParams();
    }
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    const {
      load,
      distributionPanel,
      mapBreakersInPanelToBreakerStore,
      energyPros,
      subscribeToEnergyProSensorReadings,
      unsubscribeFromEnergyProSensorReadings,
      streamConnected,
    } = this.props;
    const { activeEnergyProId, activeTab, editorView } = this.state;
    if (
      !equals(activeTab, prevState.activeTab) ||
      !equals(editorView, prevState.editorView)
    ) {
      load();
    }

    if (
      !equals(distributionPanel, prevProps.distributionPanel) &&
      distributionPanel
    ) {
      mapBreakersInPanelToBreakerStore(distributionPanel.breakers);
    }

    if (energyPros.length > 0 && prevState.activeEnergyProId === -1) {
      this.setState(
        {
          activeEnergyProId: energyPros[0].id,
        },
        () => {
          this.changeStateForUrlParams();
        }
      );
    }

    if (!equals(activeEnergyProId, prevState.activeEnergyProId)) {
      if (prevState.activeEnergyProId !== -1) {
        const prevActiveEnergyPro = energyPros.find(
          ePro => ePro.id === prevState.activeEnergyProId
        );

        if (prevActiveEnergyPro) {
          const { vendor, uuid, id } = prevActiveEnergyPro;
          unsubscribeFromEnergyProSensorReadings(vendor, uuid, id);
        }
      }

      if (activeEnergyProId !== -1) {
        const activeEnergyPro = energyPros.find(
          ePro => ePro.id === activeEnergyProId
        );

        if (activeEnergyPro) {
          const { vendor, uuid, id } = activeEnergyPro;
          if (streamConnected) {
            subscribeToEnergyProSensorReadings(vendor, uuid, id);
          } else {
            this.pendingSubscribeToEnergyProSensorReadings = true;
          }
        }
      }
    }

    if (
      streamConnected &&
      !prevProps.streamConnected &&
      this.pendingSubscribeToEnergyProSensorReadings
    ) {
      const activeEnergyPro = energyPros.find(
        ePro => ePro.id === activeEnergyProId
      );
      if (activeEnergyPro) {
        const { vendor, uuid, id } = activeEnergyPro;
        subscribeToEnergyProSensorReadings(vendor, uuid, id);
        this.pendingSubscribeToEnergyProSensorReadings = false;
      }
    }

    // Prevent the Chart re-renders too frequently
    const newEnergyProsForChart = energyPros.map(({ id, title }) => ({
      id,
      title,
    }));
    if (!equals(newEnergyProsForChart, prevState.energyProsForChart)) {
      this.setState({
        energyProsForChart: newEnergyProsForChart,
      });
    }
  }

  componentWillUnmount() {
    const { unsubscribeFromEnergyProSensorReadings, energyPros } = this.props;
    const { activeEnergyProId } = this.state;

    const activeEnergyPro = energyPros.find(
      ePro => ePro.id === activeEnergyProId
    );

    if (activeEnergyPro) {
      const { vendor, uuid, id } = activeEnergyPro;
      unsubscribeFromEnergyProSensorReadings(vendor, uuid, id);
    }
  }

  componentWillReceiveProps(nextProps) {
    const { expandedId } = this.state;
    // This is for when we delete something, we need to make sure we un-expand it.
    if (expandedId !== '') {
      const energyPros = nextProps.energyPros || [];
      const findEnergyProById = energyPros.find(
        ePro => expandedId === String(ePro.id)
      );
      const findEnergyDeviceById = this.energyDevicesList(energyPros).find(
        (energyDevice: EnergyDeviceFromApi) =>
          expandedId === String(energyDevice.id)
      );
      const findDistributionPanelById = expandedId === this.props.id;

      if (
        !findEnergyProById &&
        !findEnergyDeviceById &&
        !findDistributionPanelById
      ) {
        this.setState({
          expandedId: '',
        });
      }
    }
  }

  changeStateForUrlParams = () => {
    const { hash } = history.location;
    const paramTokens = hash.split('/');
    if (
      hash.indexOf(Routes.ENERGY_PRO_SETTINGS) > -1 ||
      hash.indexOf(Routes.ENERGY_PRO_MAINS) > -1
    ) {
      this.onEnergyProClick(paramTokens[1]);
    } else if (hash.indexOf(Routes.ENERGY_DEVICE_PORTS) > -1) {
      this.onEnergyDeviceClick(paramTokens[1]);
    } else if (hash.indexOf(Routes.ENERGY_PRO_LIVE_READINGS) > -1) {
      this.setState({
        activeEnergyProId: Number(paramTokens[1]),
        activeTab: DistributionPanelTab.EPRO_LIVE_READING,
      });
    }
  };

  energyDevicesList = (energyPros: EnergyPro[]) => {
    let energyDevicesArray: EnergyDeviceFromApi[] = [];
    const collectEnergyDevicesRecursively = (
      energyDevice: EnergyDeviceFromApi | null
    ) => {
      if (!!energyDevice) {
        energyDevicesArray.push(energyDevice);
        collectEnergyDevicesRecursively(energyDevice.busDevice);
      }
    };

    energyPros.forEach((energyPro: EnergyPro) => {
      collectEnergyDevicesRecursively(energyPro.bus1Device);
      collectEnergyDevicesRecursively(energyPro.bus2Device);
    });

    return energyDevicesArray;
  };

  toggleCardState = (id: string) => {
    this.setState({
      expandedId: id,
    });
  };

  handleDeletePanelClick = (isEPro2: boolean = false) => {
    this.props.primePanelForDelete();
    isEPro2 &&
      this.props.primeEProForDelete(String(this.state.activeEnergyProId));
    this.props.showDeleteDistributionPanelModal();
  };

  handleNewEnergyDeviceModalClick = (deviceType: EnergyDeviceType) => {
    this.setState({ newEnergyDeviceType: deviceType });
    this.props.showNewEnergyDeviceModal();
  };

  handleActiveEnergyProChange = (selectedEnergyProId: number) => {
    this.setState({
      activeEnergyProId: selectedEnergyProId,
    });
  };

  onCircuitBreakerClick = (breakerId: number, breakerPole: number) => {
    const { id, showNewEnergyDeviceSensorModal } = this.props;
    const { selectedEnergyDevice, activeEnergyProId } = this.state;

    //must differentiate between clicking a breaker independently (else statement)
    //vs clicking it to connect to an energyDevice port (if statement)
    if (
      activeEnergyProId !== -1 &&
      selectedEnergyDevice.id !== activeEnergyProId &&
      selectedEnergyDevice.energyDevicePort !== -1
    ) {
      this.setState({
        selectedEnergyDevice: {
          ...selectedEnergyDevice,
          breakerId,
          breakerPole,
        },
      });
      showNewEnergyDeviceSensorModal(selectedEnergyDevice.id);
    } else {
      this.setState({
        selectedEnergyDevice: defaultSelectedEnergyDeviceState,
        expandedId: id,
        selectedBreakerId: breakerId,
      });
    }
  };

  onPanelMainBreakerClick = (breakerId: number, breakerPole: number) => {
    const { id, showNewEnergyDeviceSensorModal } = this.props;
    const { selectedEnergyDevice, activeEnergyProId } = this.state;

    //must differentiate between clicking a breaker independently (else statement)
    //vs clicking it to connect to an energyDevice port (if statement)
    if (
      activeEnergyProId !== -1 &&
      selectedEnergyDevice.id !== -1 &&
      selectedEnergyDevice.energyDevicePort !== -1
    ) {
      this.setState({
        selectedEnergyDevice: {
          ...selectedEnergyDevice,
          breakerId: -1,
          breakerPole,
        },
      });
      showNewEnergyDeviceSensorModal(selectedEnergyDevice.id);
    } else {
      this.setState({
        selectedEnergyDevice: defaultSelectedEnergyDeviceState,
        expandedId: id,
        selectedBreakerId: breakerId,
      });
    }
  };

  resetSelectedBreakerId = () => {
    this.setState({
      selectedBreakerId: -1,
    });
  };

  onEnergyDeviceClick = (energyDeviceId: string, energyDevicePort?: number) => {
    this.setState({
      expandedId: String(energyDeviceId),
      selectedEnergyDevice: {
        ...defaultSelectedEnergyDeviceState,
        id: Number(energyDeviceId),
        energyDevicePort: energyDevicePort || -1,
      },
    });
  };

  resetSelectedEnergyDevice = () => {
    this.setState({
      selectedEnergyDevice: defaultSelectedEnergyDeviceState,
    });
  };

  onEnergyProClick = (energyProId: string, energyProPort?: number) => {
    this.setState({
      expandedId: String(energyProId),
      selectedEnergyDevice: {
        ...defaultSelectedEnergyDeviceState,
        id: Number(energyProId),
        energyDevicePort: energyProPort || -1,
      },
    });
  };

  onBreakerWireClick = (
    breakerId: number,
    energyDeviceId: number,
    energyDevicePort: number
  ) => {
    this.setState({
      selectedBreakerWire: {
        breakerId,
        energyDeviceId,
        energyDevicePort,
      },
    });
  };

  onWhiteSpaceClick = () => {
    const { editorView, activeEnergyProId } = this.state;
    this.setState({
      ...defaultState,
      editorView,
      activeEnergyProId,
    });
  };

  setShowUpdateSensorConfigs = (value: boolean) => {
    this.setState({
      showUpdateSensorConfigs: value,
    });
  };

  handleUpdateSensorConfigs = (energyProId: number | string) => {
    this.props.patchSensorConfigs(energyProId);
    this.props.clearStagingSensorConfig();
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  };

  filterEnergyDevicesById = (
    activeEnergyPro: EnergyPro | undefined,
    energyDevicesById: EnergyDevicesById
  ) => {
    if (!activeEnergyPro) return {};
    const arrayofEnergyDeviceIds: number[] = [];

    const recursivelyAddEnergyDeviceId = (
      energyDevice: EnergyDeviceFromApi
    ) => {
      arrayofEnergyDeviceIds.push(energyDevice.id);
      if (energyDevice.busDevice) {
        recursivelyAddEnergyDeviceId(energyDevice.busDevice);
      }
    };

    if (activeEnergyPro.bus1Device) {
      recursivelyAddEnergyDeviceId(activeEnergyPro.bus1Device);
    }
    if (activeEnergyPro.bus2Device) {
      recursivelyAddEnergyDeviceId(activeEnergyPro.bus2Device);
    }

    return getMapFromArrayOneToOne(
      Object.values(energyDevicesById).filter(energyDevice =>
        arrayofEnergyDeviceIds.includes(Number(energyDevice.id))
      )
    );
  };

  onConfirmUpdateEnergyProConfigModal = (
    activeEnergyPro: EnergyPro | undefined
  ) => {
    const { updateEnergyProConfiguration } = this.props;
    if (isDefined(activeEnergyPro)) {
      updateEnergyProConfiguration(String(activeEnergyPro.id));
    }
  };

  selectView = (editorView: EditorView) => {
    this.setState({ editorView });
  };

  render() {
    const {
      id,
      distributionPanel,
      currentSiteId,
      energyPros = [],
      showNewEnergyProModal,
      showNewCircuitBreakerModal,
      energyDevicesById,
      energyReadingsBySensorId,
      showingNewEnergyDeviceSensorModal,
      energyProEditById,
      showUpdateEnergyProConfigModal,
      hideUpdateEnergyProConfigModal,
      distributionPanelApiError,
      haveStagingConfigs,
    } = this.props;

    const {
      expandedId,
      newEnergyDeviceType,
      activeEnergyProId,
      selectedBreakerId,
      selectedEnergyDevice,
      selectedBreakerWire,
      activeTab,
      energyProsForChart,
      editorView,
      showUpdateSensorConfigs,
    } = this.state;

    const activeEnergyPro = energyPros.find(
      ePro => ePro.id === activeEnergyProId
    );

    const isUpdateEnergyProConfigModalShowing =
      (energyProEditById[activeEnergyProId] || {})
        .showUpdateEnergyProConfigModal || false;

    const editEnergyProApiError: ApiError | undefined =
      energyProEditById[activeEnergyProId]?.apiError;

    const filteredEnergyDevicesById = this.filterEnergyDevicesById(
      activeEnergyPro,
      energyDevicesById
    );

    const energyProConfigModalText = (
      <span>
        Are you sure you want to update the configuration of{' '}
        {activeEnergyPro ? (
          <span className={styles.bold}>{activeEnergyPro.title}</span>
        ) : (
          'this Energy Pro'
        )}
        ?
      </span>
    );

    if (!distributionPanel) {
      if (Object.keys(distributionPanelApiError).length) {
        return accessDeniedError(distributionPanelApiError);
      } else {
        return null;
      }
    }
    const { type: panelType } = distributionPanel;

    // isEPro1 & isEPro2 are used for conditional UI rendering only
    const isLoading = activeEnergyPro === undefined;
    const isEPro1 = isEnergyPro1(activeEnergyPro) && !isLoading;
    const isEPro2 = isEnergyPro2(activeEnergyPro) && !isLoading;

    const PanelEditor = distributionPanel && (
      <>
        <div className={styles.panelEditorContainer}>
          <div className={styles.flexContainer}>
            <EnergyComponentsVerticalBar>
              <VerticalNavBarButton
                onClick={() =>
                  this.handleNewEnergyDeviceModalClick(
                    EnergyDeviceType.ENERGY_BAR
                  )
                }
                icon={<EnergyBarIcon size={20} />}
              >
                Energy Bar
              </VerticalNavBarButton>

              <VerticalNavBarButton
                onClick={() =>
                  this.handleNewEnergyDeviceModalClick(
                    EnergyDeviceType.ENERGY_SPIDER
                  )
                }
                icon={<EnergySpiderIcon size={20} />}
              >
                Energy Spider
              </VerticalNavBarButton>

              <VerticalNavBarButton
                onClick={() =>
                  this.handleNewEnergyDeviceModalClick(
                    EnergyDeviceType.ENERGY_SPIDER_FLEX
                  )
                }
                icon={<EnergySpiderFlexIcon size={16} />}
              >
                Energy Spider Flex
              </VerticalNavBarButton>

              <VerticalNavBarButton
                onClick={showNewCircuitBreakerModal}
                icon={<CircuitBreakerIcon size={20} />}
              >
                Circuit Breaker
              </VerticalNavBarButton>
            </EnergyComponentsVerticalBar>
          </div>

          <div className={styles.flexContainer}>
            {selectedEnergyDevice.energyDevicePort !== -1 && (
              <div className={styles.connectPortContainer}>
                <div>
                  Click on a blue breaker to connect with selected energy device
                  sensor port
                </div>
                <div
                  className={styles.cancelButton}
                  onClick={this.resetSelectedEnergyDevice}
                >
                  Cancel
                </div>
              </div>
            )}

            <SelectActiveEnergyPro
              className={styles.energyProSelector}
              energyPros={energyPros}
              activeEnergyPro={activeEnergyPro}
              onChange={this.handleActiveEnergyProChange}
            />

            <div
              onClick={e => {
                e.stopPropagation();
                this.onWhiteSpaceClick();
              }}
            >
              <Panel
                breakerSlots={distributionPanel.breakerSlots || 1}
                breakerColumns={distributionPanel.breakerColumns || 1}
                breakers={distributionPanel.breakers}
                mainBreaker={distributionPanel.mainBreaker}
                energyProId={activeEnergyPro ? activeEnergyPro.id : undefined}
                energyReadingsBySensorId={energyReadingsBySensorId}
                energyPro={activeEnergyPro}
                firstBusConnection={
                  activeEnergyPro && activeEnergyPro.bus1Device
                }
                secondBusConnection={
                  activeEnergyPro && activeEnergyPro.bus2Device
                }
                breakersById={distributionPanel.breakers.reduce(
                  (acc, breaker) => {
                    acc[breaker.breaker.id] = breaker;
                    return acc;
                  },
                  {}
                )}
                energyDevicesById={filteredEnergyDevicesById}
                onCircuitBreakerClick={this.onCircuitBreakerClick}
                onEnergyDeviceClick={this.onEnergyDeviceClick}
                onEnergyProClick={this.onEnergyProClick}
                onBreakerWireClick={this.onBreakerWireClick}
                selectedBreakerWire={selectedBreakerWire}
                selectedEnergyDevice={selectedEnergyDevice}
                selectedResourceId={
                  expandedId === id ? selectedBreakerId : Number(expandedId)
                }
                onPanelMainBreakerClick={this.onPanelMainBreakerClick}
              />
            </div>
          </div>

          <div className={styles.flexContainer}>
            <div className={styles.listGrouping}>
              {activeEnergyPro && (
                <DistributionPanelConfiguration
                  toggleCardState={this.toggleCardState}
                  expandedId={expandedId}
                  id={id}
                  currentSiteId={currentSiteId}
                  selectedBreakerId={selectedBreakerId}
                  resetSelectedBreakerId={this.resetSelectedBreakerId}
                  mainBreaker={distributionPanel.mainBreaker}
                  panelColumns={distributionPanel.breakerColumns || 2}
                  energyPro={activeEnergyPro}
                />
              )}
              {energyPros &&
                energyPros.map(energyPro => {
                  return (
                    <div
                      key={energyPro.id}
                      className={styles.energyProGrouping}
                    >
                      <EnergyProConfiguration
                        siteId={currentSiteId}
                        id={String(energyPro.id)}
                        toggleCardState={this.toggleCardState}
                        expandedId={expandedId}
                        distributionPanelEnergySource={
                          distributionPanel.energySource
                        }
                        handleEnergyProChange={this.handleActiveEnergyProChange}
                      />
                      <RecursiveEnergyDevicesList
                        energyPro={energyPro}
                        isFirstItem
                        energyDevice={energyPro.bus1Device}
                        toggleCardState={this.toggleCardState}
                        expandedId={expandedId}
                        predeterminedBus={1}
                        panelId={id}
                        selectedEnergyDevice={selectedEnergyDevice}
                        resetSelectedEnergyDevice={
                          this.resetSelectedEnergyDevice
                        }
                        handleEnergyProChange={this.handleActiveEnergyProChange}
                      />
                      <RecursiveEnergyDevicesList
                        energyPro={energyPro}
                        isFirstItem
                        energyDevice={energyPro.bus2Device}
                        toggleCardState={this.toggleCardState}
                        expandedId={expandedId}
                        predeterminedBus={2}
                        panelId={id}
                        selectedEnergyDevice={selectedEnergyDevice}
                        resetSelectedEnergyDevice={
                          this.resetSelectedEnergyDevice
                        }
                        handleEnergyProChange={this.handleActiveEnergyProChange}
                      />
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </>
    );

    return (
      <>
        <ShowDetailPageHeader
          name={distributionPanel.title}
          description={<ResourceFullPath resourceId={distributionPanel.id} />}
          resourceName="Panel"
          onDelete={() => this.handleDeletePanelClick(isEPro2)}
        />

        <div className={styles.root}>
          <div className={styles.tabsContainer}>
            <Tabs>
              {Object.values(DistributionPanelTab).map(tab => (
                <Tab
                  active={activeTab === tab}
                  onClick={() => this.setState({ activeTab: tab })}
                  key={tab}
                >
                  {tab}
                </Tab>
              ))}
            </Tabs>
          </div>
          <div className={styles.scrollContainer}>
            {activeTab === DistributionPanelTab.PANEL_EDITOR && (
              <div className={styles.editorController}>
                {isEPro1 ? (
                  <RadioGroup>
                    <RadioButton
                      label={<EnergyProIcon size={14} />}
                      value={EditorView.SVG}
                      checked={editorView === EditorView.SVG}
                      onChange={() => this.selectView(EditorView.SVG)}
                    />
                    <RadioButton
                      label={<GridView size={14} />}
                      value={EditorView.TABLE}
                      checked={editorView === EditorView.TABLE}
                      onChange={() => this.selectView(EditorView.TABLE)}
                    />
                  </RadioGroup>
                ) : (
                  <div />
                )}
                {isEPro2 ? (
                  <Button
                    onClick={() => this.setShowUpdateSensorConfigs(true)}
                    disabled={!haveStagingConfigs}
                  >
                    Update Configuration
                  </Button>
                ) : (
                  <Button
                    onClick={() =>
                      showUpdateEnergyProConfigModal(String(activeEnergyProId))
                    }
                  >
                    Update Configuration To Edge
                  </Button>
                )}
              </div>
            )}

            {activeTab === DistributionPanelTab.PANEL_EDITOR &&
              (isEPro2 || editorView === EditorView.TABLE) && (
                <DPSettingsCard
                  className={styles.dpSettingsCard}
                  distributionPanel={distributionPanel}
                  activeEnergyProId={isEPro2 ? activeEnergyPro?.id : undefined}
                />
              )}

            {isEPro1 &&
              activeTab === DistributionPanelTab.PANEL_EDITOR &&
              editorView === EditorView.SVG &&
              PanelEditor}
            {isEPro1 &&
              activeTab === DistributionPanelTab.PANEL_EDITOR &&
              editorView === EditorView.TABLE && (
                <>
                  <div className={styles.dpTableAddDeviceTool}>
                    <div className={styles.addButton}>
                      <MdAdd size={32} strokeWidth={0.05} />
                    </div>
                    <div className={styles.addArrow}>
                      <ArrowRight size={18} strokeWidth={1} />
                    </div>
                    <div className={styles.dpTableAddDeviceButtonContainer}>
                      <VerticalNavBarButton
                        className={styles.dpTableAddDeviceButton}
                        onClick={() =>
                          this.handleNewEnergyDeviceModalClick(
                            EnergyDeviceType.ENERGY_BAR
                          )
                        }
                        icon={<EnergyBarIcon size={20} />}
                      >
                        Energy Bar
                      </VerticalNavBarButton>

                      <VerticalNavBarButton
                        className={styles.dpTableAddDeviceButton}
                        onClick={() =>
                          this.handleNewEnergyDeviceModalClick(
                            EnergyDeviceType.ENERGY_SPIDER
                          )
                        }
                        icon={<EnergySpiderIcon size={20} />}
                      >
                        Energy Spider
                      </VerticalNavBarButton>

                      <VerticalNavBarButton
                        className={styles.dpTableAddDeviceButton}
                        onClick={() =>
                          this.handleNewEnergyDeviceModalClick(
                            EnergyDeviceType.ENERGY_SPIDER_FLEX
                          )
                        }
                        icon={<EnergySpiderFlexIcon size={16} />}
                      >
                        Energy Spider Flex
                      </VerticalNavBarButton>

                      <VerticalNavBarButton
                        className={styles.dpTableAddDeviceButton}
                        onClick={showNewCircuitBreakerModal}
                        icon={<CircuitBreakerIcon size={20} />}
                      >
                        Circuit Breaker
                      </VerticalNavBarButton>
                    </div>
                  </div>
                </>
              )}
            {activeTab === DistributionPanelTab.PANEL_EDITOR &&
              (isEPro2 || editorView === EditorView.TABLE) && (
                <DPSetUpTable
                  siteId={currentSiteId}
                  energyPros={energyPros}
                  activeEnergyPro={activeEnergyPro}
                  activeEnergyProId={activeEnergyProId}
                  setActiveEnergyProsId={this.handleActiveEnergyProChange}
                  distributionPanel={distributionPanel}
                />
              )}

            {activeTab === DistributionPanelTab.EPRO_LIVE_READING && (
              <div className={styles.liveReadingTable}>
                {panelType === DistributionPanelType.HIGH_LEG_DELTA_NETWORK ? (
                  <DeltaPanelLiveReadingTable
                    siteId={currentSiteId}
                    energyPros={energyPros}
                    activeEnergyPro={activeEnergyPro}
                    panelId={distributionPanel.id}
                    handleActiveEnergyProChange={
                      this.handleActiveEnergyProChange
                    }
                  />
                ) : (
                  <GenericPanelLiveReadingTable
                    siteId={currentSiteId}
                    energyPros={energyPros}
                    activeEnergyPro={activeEnergyPro}
                    panelId={distributionPanel.id}
                    handleActiveEnergyProChange={
                      this.handleActiveEnergyProChange
                    }
                  />
                )}
              </div>
            )}
            {activeTab === DistributionPanelTab.CHARTS && (
              <DistributionPanelCharts
                siteId={currentSiteId}
                energyPros={energyProsForChart}
                activeEnergyPro={activeEnergyPro}
                handleActiveEnergyProChange={this.handleActiveEnergyProChange}
              />
            )}
          </div>
        </div>
        <DeleteDistributionPanelModal
          redirectAfterDelete
          siteId={currentSiteId}
        />
        <NewCircuitBreakerModal
          distributionPanel={distributionPanel}
          panelId={distributionPanel.id}
          currentSiteId={currentSiteId}
          breakerSlotRows={distributionPanel.breakerSlots}
          panelColumns={distributionPanel.breakerColumns || 1}
        />
        <NewEnergyDeviceModal
          panelId={distributionPanel.id}
          newEnergyDeviceType={newEnergyDeviceType}
        />
        {showingNewEnergyDeviceSensorModal &&
          selectedEnergyDevice.id !== -1 && (
            <NewEnergyDeviceSensorModal
              panelId={id}
              energyDeviceId={String(selectedEnergyDevice.id)}
              sensors={
                (energyDevicesById[selectedEnergyDevice.id] || activeEnergyPro)
                  .sensors
              }
              resetSelectedEnergyDevice={this.resetSelectedEnergyDevice}
              predeterminedData={selectedEnergyDevice}
            />
          )}
        {isUpdateEnergyProConfigModalShowing && (
          <UpdateModal
            onCancelModal={() =>
              hideUpdateEnergyProConfigModal(String(activeEnergyProId))
            }
            onConfirmUpdate={() =>
              this.onConfirmUpdateEnergyProConfigModal(activeEnergyPro)
            }
            modalText={energyProConfigModalText}
            apiError={editEnergyProApiError}
            apiErrorAction={
              EnergyProActions.UPDATE_ENERGY_PRO_CONFIGURATION_ERROR
            }
          />
        )}
        {showUpdateSensorConfigs && (
          <ConfirmUpdateSensorConfigs
            onClose={() => this.setShowUpdateSensorConfigs(false)}
            onConfirm={() => this.handleUpdateSensorConfigs(activeEnergyProId)}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = (
  {
    app,
    energyDevices,
    distributionPanels,
    energyPros,
    resourcePaths,
    subscribedEnergyPros,
  }: ApplicationState,
  { id }: OwnProps
) => ({
  energyDevicesById: energyDevices.energyDevicesById,
  distributionPanel: distributionPanels.distributionPanelsById[id],
  currentSiteId: resourcePaths.byId[id] && resourcePaths.byId[id][0].id,
  energyReadingsBySensorId:
    subscribedEnergyPros.subscribedEnergyProSensorsReadingBySensorId,
  energyPros: mapValues(energyPros.energyProsById, item => item).filter(
    ePro => {
      const ids = energyPros.energyProIdsByDistributionPanelId[id] || [];
      return ids.includes(String(ePro.id));
    }
  ),
  showingNewEnergyDeviceSensorModal:
    energyDevices.showNewEnergyDeviceSensorModal,
  streamConnected: app.streamConnected,
  energyProEditById: energyPros.editById,
  distributionPanelApiError: distributionPanels.distributionPanelApiError,
  haveStagingConfigs: energyDevices.stagingSensorConfigs.length > 0,
});

const mapDispatchToProps = (dispatch: any, { id }: OwnProps) => ({
  load: () => {
    dispatch(getDistributionPanel(id));
    dispatch(getEnergyProsByDistributionPanelId(parseInt(id)));
  },
  subscribeToEnergyProSensorReadings: (vendor, uuid, id) =>
    dispatch(subscribeToEnergyProSensorReadings(vendor, uuid, id)),
  unsubscribeFromEnergyProSensorReadings: (vendor, uuid, id) =>
    dispatch(unsubscribeFromEnergyProSensorReadings(vendor, uuid, id)),
  showNewEnergyProModal: () => dispatch(showNewEnergyProModal()),
  showDeleteDistributionPanelModal: () =>
    dispatch(showDeleteDistributionPanelModal()),
  primePanelForDelete: () => dispatch(primePanelForDelete(parseInt(id))),
  primeEProForDelete: (id: string) => dispatch(primeEProForDelete(id)),
  showNewCircuitBreakerModal: () => dispatch(showNewCircuitBreakerModal()),
  mapBreakersInPanelToBreakerStore: (
    breakersInPanel: CircuitBreakerInPanel[]
  ) => dispatch(mapBreakersInPanelToBreakerStore(breakersInPanel)),
  showNewEnergyDeviceModal: () => dispatch(showNewEnergyDeviceModal()),
  showNewEnergyDeviceSensorModal: (energyDeviceId: number) =>
    dispatch(showNewEnergyDeviceSensorModal(energyDeviceId)),
  updateEnergyProConfiguration: (energyProId: string) =>
    dispatch(updateEnergyProConfiguration(energyProId)),
  showUpdateEnergyProConfigModal: (energyProId: string) =>
    dispatch(showUpdateEnergyProConfigModal(energyProId)),
  hideUpdateEnergyProConfigModal: (energyProId: string) =>
    dispatch(hideUpdateEnergyProConfigModal(energyProId)),
  patchSensorConfigs: (id: string | number) => dispatch(patchSensorConfigs(id)),
  clearStagingSensorConfig: () => dispatch(clearStagingSensorConfig()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ShowDistributionPanelPage);
