import React from 'react';
import {
  classNames,
  formatNumberString,
  isDefined,
} from '@energybox/react-ui-library/dist/utils';

import styles from './LiveReadingTable.module.css';
import { RadioButton } from '@energybox/react-ui-library/dist/components';

export type RowCellFormatter = {
  label?: string;
  value?: number | string;
  unit?: string;
  decimal?: number;
};

type Props = {
  headers: string[];
  rows: Array<RowCellFormatter[]>;
  selectedRowIndex?: number | null;
  onSelectRow?: (index: number | null) => void;
};

const LiveReadingTable = ({
  headers,
  rows,
  selectedRowIndex,
  onSelectRow,
}: Props) => {
  const hasCheckboxColumn = isDefined(onSelectRow);
  const columnCount = hasCheckboxColumn ? headers.length + 1 : headers.length;
  const headerElement = (
    <div className={styles[`columns_${columnCount}`]}>
      {hasCheckboxColumn && <div className={styles.header}></div>}
      {headers.map((header, index) => (
        <div key={`${header}column${index}`} className={styles.header}>
          {header}
        </div>
      ))}
    </div>
  );

  return (
    <div>
      {headerElement}
      {rows.map((row, index) => {
        const checked = selectedRowIndex === index;
        const onClick = () =>
          hasCheckboxColumn && onSelectRow(checked ? null : index);
        return (
          <div
            className={classNames(
              styles[`columns_${columnCount}`],
              styles.valueRow
            )}
            onClick={onClick}
            style={
              hasCheckboxColumn
                ? {
                    cursor: 'pointer',
                    backgroundColor: checked
                      ? 'var(--accent-basePlus75)'
                      : undefined,
                  }
                : undefined
            }
          >
            {hasCheckboxColumn && (
              <div className={styles.value}>
                <RadioButton
                  label=""
                  value=""
                  variant="traditional"
                  size={18}
                  checked={checked}
                  onChange={onClick}
                />
              </div>
            )}
            {row.map(cellFormatter => {
              return renderCellContent(cellFormatter);
            })}
          </div>
        );
      })}
    </div>
  );
};

const renderCellContent = ({
  label,
  value,
  unit,
  decimal,
}: RowCellFormatter) => {
  if (typeof value === 'string') {
    return <div className={styles.value}>{value}</div>;
  }
  if (label) {
    return <div className={styles.value}>{label}</div>;
  }
  return (
    <div className={styles.value}>
      {formatNumberString(value, {
        unit,
        numDecimals: decimal,
        useNotAvailableText: true,
      })}
    </div>
  );
};

export default LiveReadingTable;
