import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

const useUnsavedChangesPrompt = (isDirty, onLeave?) => {
  const history = useHistory();

  useEffect(() => {
    // Block navigation if there are unsaved changes
    const unblock = history.block(location => {
      if (isDirty) {
        const confirmLeave = window.confirm(
          'You have unsaved changes. Are you sure you want to discard changes and leave?'
        );
        if (confirmLeave) {
          onLeave();
          unblock(); // Unblock navigation
          history.push(location.pathname); // Proceed with navigation
        } else {
          return false; // Prevent navigation
        }
      }
    });

    return () => {
      unblock(); // Cleanup on unmount
    };
  }, [isDirty, history]);

  useEffect(() => {
    const handleBeforeUnload = event => {
      if (isDirty) {
        event.preventDefault(); // Prevent default action
        event.returnValue = ''; // Chrome requires returnValue to be set
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [isDirty]); // Dependency array includes isDirty
};

export default useUnsavedChangesPrompt;
